<template>
  <section>
    <div class="search-top">
<!--      <div class="ListFilter">
        <div class="FilterItem mr-2">
          <label>상태</label>
          <select
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="">임시저장</option>
            <option value="">확인필요</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label for="category1">대분류</label>
          <select
            id="category1"
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="1">아트</option>
            <option value="4">럭셔리</option>
            <option value="3">컬쳐</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label for="category2">소분류</label>
          <select
            id="category2"
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="1">아트</option>
            <option value="4">럭셔리</option>
            <option value="3">컬쳐</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label for="option" >판매옵션</label>
          <select
            id="option"
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="true">Bid Now</option>
            <option value="false">Bid Now & Buy Now</option>
            <option value="false">Buy Now Only</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label for="makeOffeer" >Make Offer 허용</label>
          <select
            id="makeOffeer"
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="true">허용</option>
            <option value="false">허용안함</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label>구매제한</label>
          <select
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="true">제한없음</option>
            <option value="false">제한있음</option>
          </select>
        </div>
        <div class="FilterItem mr-2">
          <label>과세/면세</label>
          <select
            class="FormSelect h-8  inline-flex px-4 appearance-none focus:outline-none rounded-none text-sm"
          >
            <option value="">전체</option>
            <option value="true">면세상품</option>
            <option value="false">과세상품</option>
          </select>
        </div>
      </div>
      <div class="inline-flex search-box mt-4">
        <input
          type="text"
          class="Filter__textInput border border-black flex-1 h-10 py-3 px-10 text-xs"
          placeholder="작가/상품 명"
        />
        <button class="search-button h-10 ml-2 bg-black text-white text-xs" type="submit">
          검색
        </button>
      </div>
-->
      <p class="text-sm mt-4 mb-4">
        전체 {{ dataList.length }}건
      </p>
      <div class="flex items-center">
        <button
          class="h-9 inline-flex items-center justify-center text-white text-sm w-40 bg-black"
          @click="handleUploadButtonClick"
        ><img src="@/assets/icon_upload_white.svg" alt="" class="mr-2"> 템플릿 업로드</button>
        <input
          type="file"
          multiple
          name="productImageList"
          accept=".xlsx"
          class="w-px h-px opacity-0 overflow-hidden absolute"
          ref="uploadFileInputEl"
          @change="handleChangeExcelInput"
        />
        <a href="/blacklot_upload_sample.xlsx" class="mx-2 inline-flex text-sm h-8 w-34 items-center">
          <IconBase class="mr-1">
            <FileExcel></FileExcel>
          </IconBase>
          업로드 템플릿 받기
        </a>
        <button class="inline-flex text-sm w-34 items-center">
          <Guide  class="mr-1"></Guide>
          상품등록방법 안내
        </button>
      </div>
    </div>
    <div class="table-area mt-4">
      <table class="table">
        <thead>
          <tr>
            <th class="text-sm font-semibold table-th table-width">
              <input
                type="checkbox"
                class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
                @click="handleAllCheck"
              />
              No.
            </th>
            <th class="text-sm font-semibold table-th table-width">상태</th>
            <th class="text-sm font-semibold table-th table-width">미리보기</th>
            <th class="text-sm font-semibold table-th table-width">상품이미지</th>
            <th class="text-sm font-semibold table-th table-width">대분류 <span class="required">*</span></th>
            <th class="text-sm font-semibold table-th table-width">소분류 <span class="required">*</span></th>
            <th class="text-sm font-semibold table-th table-width">작품명(or 제품명/모델명) 한글</th>
            <th class="text-sm font-semibold table-th table-width">작품명(or 제품명/모델명) 영어</th>
            <th class="text-sm font-semibold table-th table-width">작가명(or 브랜드/디자이너명) 한글</th>
            <th class="text-sm font-semibold table-th table-width">작품명(or 제품명/모델명) 영어</th>
            <th class="text-sm font-semibold table-th table-width">재질</th>
            <th class="text-sm font-semibold table-th table-width">사이즈</th>
            <th class="text-sm font-semibold table-th table-width">제작시기</th>
            <th class="text-sm font-semibold table-th table-width">제작년도</th>
            <th class="text-sm font-semibold table-th table-width">에디션</th>
            <th class="text-sm font-semibold table-th table-width">국가</th>
            <th class="text-sm font-semibold table-th table-width">서명정보</th>
            <th class="text-sm font-semibold table-th table-width">보증서유무</th>
            <th class="text-sm font-semibold table-th table-width">상태(컨디션)</th>
            <th class="text-sm font-semibold table-th table-width">태그</th>
            <th class="text-sm font-semibold table-th table-width">소장이력</th>
            <th class="text-sm font-semibold table-th table-width">전시이력</th>
            <th class="text-sm font-semibold table-th table-width">수록처</th>
            <th class="text-sm font-semibold table-th table-width">작가정보</th>
            <th class="text-sm font-semibold table-th table-width">상세설명</th>
            <th class="text-sm font-semibold table-th table-width">판매옵션</th>
            <th class="text-sm font-semibold table-th table-width">Make Offer</th>
            <th class="text-sm font-semibold table-th table-width">시작가</th>
            <th class="text-sm font-semibold table-th table-width">Buy Now 가격</th>
            <th class="text-sm font-semibold table-th table-width">판매시작일</th>
            <th class="text-sm font-semibold table-th table-width">판매종료일</th>
            <th class="text-sm font-semibold table-th table-width">배송방법</th>
            <th class="text-sm font-semibold table-th table-width">배송비</th>
            <th class="text-sm font-semibold table-th table-width">배송비부담</th>
            <th class="text-sm font-semibold table-th table-width">구매제한</th>
            <th class="text-sm font-semibold table-th table-width">면세여부</th>
            <th class="text-sm font-semibold table-th table-width">EVENT Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, idx) in  dataList" :key="idx">
            <td class="text-sm table-width table-td">
              <input
                type="checkbox"
                class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
                :checked="data.checked"
                @click="(e) => handleCheck(e, idx)"
              /> {{ idx + 1 }}
            </td>
            <td
              class="text-sm table-width table-td"
              :class="{'text-red-600': !data.allPass}"
            ><!-- 상태 -->
              {{ data.allPass ? '임시저장' : '확인필요' }}
            </td>
            <td class="text-sm table-width table-td"> <!--미리보기-->
              <button
                v-if="data.allPass"
                class="w-30 h-9 button-shadow inline-flex text-black items-center justify-center border border-black text-sm bg-white"
                @click="handleClickPreview(data.productTempId)"
              >미리보기</button>
              <span v-else>-</span>
            </td>
            <td class="text-sm table-width table-td file-td"><!-- 이미지 -->
<!--              등록한 이미지 1개도 없을때-->
              <p v-if="!data.productImageList || data.productImageList.length === 0" class="input-file-area">
                <label :for="`upload${idx}`" class="file-label">클릭해서 이미지를 선택해주세요.</label>
                <input
                  type="file"
                  :id="`upload${idx}`"
                  class="file-input"
                  accept=".jpg,.jpeg,.png"
                  multiple
                  @change="(e) => handleImageUpload(e, idx)"
                >
              </p>
              <!--              등록한 이미지 1개도 없을때-->
              <!--              등록한 이미지 1개이상-->
              <div v-else class="input-file-area">
                <div class="input-file-images">
                  <div
                    v-for="(img, imgIdx) in data.productImageList"
                    :key="imgIdx"
                    class="ProductImageItem w-11 mb-1 h-11 bg-gray-200 mr-1 bg-cover bg-center relative flex items-center justify-center"
                    :style="{ backgroundImage: `url(${img.url})` }"
                  >
                    <button
                      class="text-white product-img-button"
                      @click="handleRemoveProductImage(idx, imgIdx)"
                    >
                      <img src="@/assets/icon_delete.svg" alt=""/>
                    </button>
                  </div>
                </div>
                <label :for="`upload${idx}`" class="file-label"></label>
                <input
                  type="file"
                  :id="`upload${idx}`"
                  class="file-input"
                  multiple
                  accept=".jpg,.jpeg,.png"
                  @change="(e) => handleImageUpload(e, idx)"
                >
              </div>
              <!--              등록한 이미지 1개이상-->
            </td>
            <td class="text-sm table-width table-td">
              <select
                v-model="data.categoryDepth1"
                class="register-input full select"
                :class="{'error': data.validation?.categoryDepth1 !== 'pass'}"
                @change="(e) => handleChangeCategoryDepth1(e, idx)"
              >
                <option value="">카테고리 선택</option>
                <option
                  v-for="(ct1) in categoryDepth1Options"
                  :key="ct1.value"
                  :value="ct1.label"
                >{{ ct1.label }}</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <select
                v-model="data.categoryDepth2"
                class="register-input full select"
                :class="{'error': data.validation?.categoryDepth2 !== 'pass'}"
              >
                <option value="">카테고리 선택</option>
                <option
                  v-for="(ct2) in data.categoryDepth2Options"
                  :key="ct2.value"
                  :value="ct2.label"
                >{{ ct2.label }}</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.titleKr" type="text" class="register-input full" :class="{'error': data.validation?.title !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.titleEn" type="text" class="register-input full" :class="{'error': data.validation?.title !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.artistNameKr" type="text" class="register-input full" :class="{'error': data.validation?.artistName !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.artistNameEn" type="text" class="register-input full" :class="{'error': data.validation?.artistName !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.medium" type="text" class="register-input full" :class="{'error': data.validation?.medium !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.size" type="text" class="register-input full" :class="{'error': data.validation?.size !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.period" type="text" class="register-input full" :class="{'error': data.validation?.period !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.makeYear" type="text" class="register-input full" :class="{'error': data.validation?.makeYear !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.edition" type="text" class="register-input full" :class="{'error': data.validation?.edition !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.country" type="text" class="register-input full" :class="{'error': data.validation?.country !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.signature" type="text" class="register-input full" :class="{'error': data.validation?.signature !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <select v-model="data.certificate" class="register-input full select" :class="{'error': data.validation?.certificate !== 'pass'}">
                <option value="true">있음</option>
                <option value="false">없음</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.status" type="text" class="register-input full" :class="{'error': data.validation?.status !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.tags" type="text" class="register-input full" :class="{'error': data.validation?.tags !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <textarea v-model="data.ownHistory" class="register-input full textarea" :class="{'error': data.validation?.ownHistory !== 'pass'}">
              </textarea>
            </td>
            <td class="text-sm table-width table-td">
              <textarea v-model="data.exhibitionHistory" class="register-input full textarea" :class="{'error': data.validation?.exhibitionHistory !== 'pass'}">
              </textarea>
            </td>
            <td class="text-sm table-width table-td">
              <textarea v-model="data.recordedPlace" class="register-input full textarea" :class="{'error': data.validation?.recordedPlace !== 'pass'}">
              </textarea>
            </td>
            <td class="text-sm table-width table-td">
              <textarea v-model="data.artistInfo" class="register-input full textarea" :class="{'error': data.validation?.artistInfo !== 'pass'}">
              </textarea>
            </td>
            <td class="text-sm table-width table-td">
              <textarea v-model="data.description" class="register-input full textarea" :class="{'error': data.validation?.description !== 'pass'}">
              </textarea>
            </td>
            <td class="text-sm table-width table-td">
              <select
                v-model="data.salesType"
                class="register-input full select"
                :class="{'error': data.validation?.salesType !== 'pass'}"
                @change="(e) => handleChangeSellOption(e, idx)"
              >
                <option value="Bid Now">Bid Now</option>
                <option value="Bid Now & Buy Now">Bid Now & Buy Now</option>
                <option value="Buy Now Only">Buy Now Only</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <select v-model="data.makeOffer" class="register-input full select" :class="{'error': data.validation?.makeOffer !== 'pass'}">
                <option value="true">허용</option>
                <option value="false">허용안함</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.startPrice" type="text" class="register-input full" :class="{'error': data.validation?.startPrice !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.buynowPrice" type="text" class="register-input full" :class="{'error': data.validation?.buynowPrice !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <input
                :value="data.startDate ? getDateTimeLocalValue(data.startDate) : '' "
                @change="(e) => handleChangeDate(idx, 'startDate', e.target.value)"
                type="datetime-local"
                class="register-input full" :class="{'error': data.validation?.startDate !== 'pass'}"
              >
            </td>
            <td class="text-sm table-width table-td">
              <input
                :value="data.endDate ? getDateTimeLocalValue(data.endDate) : '' "
                @change="(e) => handleChangeDate(idx, 'endDate', e.target.value)"
                type="datetime-local"
                class="register-input full" :class="{'error': data.validation?.endDate !== 'pass'}"
              >
            </td>
            <td class="text-sm table-width table-td">
              <select
                v-model="data.shippingType"
                class="register-input full select" :class="{'error': data.validation?.shippingType !== 'pass'}"
              >
                <option value="">배송방법</option>
                <option v-for="(st, idx) in shippingTypeOptions" :value="st.label" :key="idx">
                  {{ st.label }}
                </option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.shippingFee" type="text" class="register-input full" :class="{'error': data.validation?.shippingFee !== 'pass'}">
            </td>
            <td class="text-sm table-width table-td">
              <select v-model="data.feePrepaidShipping" class="register-input full select" :class="{'error': data.validation?.feePrepaidShipping !== 'pass'}">
                <option value="">배송비부담</option>
                <option value="true">구매자</option>
                <option value="false">판매자</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <select v-model="data.buyLimit" class="register-input full select" :class="{'error': data.validation?.buyLimit !== 'pass'}">
                <option value="">구매제한</option>
                <option value="false">제한없음</option>
                <option value="true">제한있음</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <select v-model="data.taxFree" class="register-input full select" :class="{'error': data.validation?.taxFree !== 'pass'}">
                <option value="">면세여부</option>
                <option value="true">면세상품</option>
                <option value="false">과세상품</option>
              </select>
            </td>
            <td class="text-sm table-width table-td">
              <input v-model="data.eventCode" type="text" class="register-input full">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-bottom-buttons flex align-center justify-start mt-4">
      <button
        class="w-30 h-9 button-shadow inline-flex text-black items-center justify-center border border-black text-sm bg-white"
        @click="handleAddTenRow"
      >
        <IconBase><Plus></Plus></IconBase>
        <span class="ml-2">10행 추가</span>
      </button>
      <button
        class="w-30 h-9 button-shadow inline-flex text-black items-center justify-center border border-black text-sm bg-white ml-3"
        @click="handleValidation"
      >
        <IconBase><Reload></Reload></IconBase>
        <span class="ml-2">Data 확인</span>
      </button>
      <div class="table-bottom-line mx-3"></div>
      <button
        class="w-30 h-9 button-shadow inline-flex text-red-500 items-center justify-center border border-red-500 text-sm bg-white ml-3"
        @click="handleDelete"
      >
        <IconBase><RemoveIcon></RemoveIcon></IconBase>
        <span class="ml-2">삭제</span>
      </button>
      <button
        class="w-40 h-9 button-shadow inline-flex text-white items-center justify-center bg-black text-sm ml-3"
        @click="handleSaveData"
      >
        <IconBase><CheckCircleIcon></CheckCircleIcon></IconBase>
        <span class="ml-2">등록하기</span>
      </button>
    </div>
  </section>

</template>

<script>
import { onMounted, ref } from 'vue'
import { format } from 'date-fns'
import IconBase from '@/components/icons/IconBase.vue'
import FileExcel from '@/components/icons/FileExcel.vue'
import Guide from '@/components/icons/Guide.vue'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue'
import RemoveIcon from '@/components/icons/RemoveIcon.vue'
import Plus from '@/components/icons/Plus.vue'
import Reload from '@/components/icons/Reload.vue'
import partnerAPI from '@/service/partnerAPI'
import useCategoryOptions from '@/mixins/useCategoryOptions'
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions'
import datePattern from '@/constants/datePattern'

export default {
  name: 'BulkRegister',
  components: {
    IconBase,
    FileExcel,
    Guide,
    CheckCircleIcon,
    RemoveIcon,
    Plus,
    Reload
  },
  setup() {
    const dataList = ref([])
    const { categoryOptions, categoryDepth1OptionsRaw } = useCategoryOptions()
    const { shippingTypeOptions } = useShippingTypeOptions(false)

    const handleAllCheck = (e) => {
      dataList.value = dataList.value.map(data => {
        return {
          ...data,
          checked: e.target.checked
        }
      })
    }

    const handleCheck = (e, idx) => {
      console.log('cc', e.target.checked, idx)
      dataList.value[idx].checked = e.target.checked
    }

    const handleChangeCategoryDepth1 = (e, idx) => {
      const target = e.target.value

      dataList.value[idx].categoryDepth2 = ''
      dataList.value[idx].categoryDepth2Options = categoryOptions.value
          .find((v) => v.categoryName === target)
          ?.children
          .map(cate => {
            return {
              label: cate.categoryName,
              value: cate.categoryId
            }
          }, [])
    }

    const handleChangeSellOption = (e, idx) => {
      const option = e.target.value
      switch (option) {
        case 'Bid Now':
          dataList.value[idx].enableBid = true
          dataList.value[idx].buynowOnly = false
          dataList.value[idx].enableBuyNow = false
          break
        case 'Bid Now & Buy Now':
          dataList.value[idx].enableBid = true
          dataList.value[idx].buynowOnly = false
          dataList.value[idx].enableBuyNow = true
          break
        case 'Buy Now Only':
          dataList.value[idx].enableBid = false
          dataList.value[idx].buynowOnly = true
          dataList.value[idx].enableBuyNow = false
          break
      }
    }

    const handleImageUpload = async (e, idx) => {
      if (e.target.files && e.target.files[0]) {
        console.log('e.target.files', e.target.files)

        const files = e.target.files
        const MAX_IMAGES = 10
        const uploadableLength = MAX_IMAGES - (dataList.value[idx].productImageList || []).length

        if (files.length > uploadableLength) {
          alert(`이미지 파일은 ${MAX_IMAGES}개까지 등록 가능합니다.`);
        }

        let productImageList = dataList.value[idx].productImageList || []
        for (let i = 0; i < Math.min(files.length, uploadableLength); i++) {
          const url = await uploadImageFile(files[i])
          productImageList = productImageList.concat([{
            id: '',
            url
          }])
        }

        console.log('productImageList', productImageList)
        dataList.value[idx].productImageList = productImageList
      }
    }

    const handleRemoveProductImage = (idx, imgIdx) => {
      const nextList = [...(dataList.value[idx].productImageList || [])]
      nextList.splice(imgIdx, 1)
      dataList.value[idx].productImageList = nextList
    }

    const handleAddTenRow = () => {
      [...Array(10)].forEach(() => {
        console.log('aa')
        dataList.value.push({})
      })
    }

    const convertData = (data) => {
      return {
        ...data,
        categoryDepth2Options: categoryOptions.value
            .find((v) => v.categoryId === data.categoryDepth1Id)
            ?.children
            .map(cate => {
              return {
                label: cate.categoryName,
                value: cate.categoryId
              }
            }, []),
        salesType: data.buynowOnly ? 'Buy Now Only' : data.enableBuyNow ? 'Bid Now & Buy Now' : 'Bid Now', // BID, BIDANDBUYNOW, BUYNOW
      }
    }

    const uploadImageFile = async (file) => {
        try {
          const { data: uploadedImageUrl } = await partnerAPI.partnerProduct.handleFileUploadUsingPOST3({
            file
          })
          console.log(uploadedImageUrl)
          return uploadedImageUrl
        } catch (e) {
          console.error(e)
        }
    }

    const fetchProductData = async () => {
      try {
        const { data: { data } } = await partnerAPI.multiProduct.listTempProductUsingGET()
        console.log(data)
        dataList.value = data.map(d => convertData(d))
      } catch (e) {
        console.error(e)
      }
    }

    const handleClickPreview = (productTempId) => {
      window.open(`${process.env.VUE_APP_FRONT_HOST}/lot/preview/${productTempId}`, '_blank')
    }

    const handleValidation = async () => {
      try {
        const checkedData = dataList.value.filter(d => d.checked === true) || []
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.')
          return
        }

        await partnerAPI.multiProduct.validationProductListUsingPOST({
          param: checkedData
        })

        alert('데이터가 갱신되었습니다.')
        await fetchProductData()

      } catch (e) {
        console.error(e)
      }
    }

    const handleDelete = async () => {
      try {
        const checkedData = dataList.value.filter(d => d.checked === true) || []
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.')
          return
        }

        await partnerAPI.multiProduct.deleteColumnUsingDELETE({
          ids: checkedData.map(d => d.productTempId).join(',')
        })

        alert('데이터가 갱신되었습니다.')
        await fetchProductData()

      } catch (e) {
        console.error(e)
      }
    }

    const handleSaveData = async () => {
      try {
        const checkedData = dataList.value.filter(d => d.checked === true) || []
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.')
          return
        }

        if (checkedData.filter(d => !d.allPass).length > 0) {
          alert('확인이 필요한 데이터가 있습니다.')
          return
        }

        await partnerAPI.multiProduct.saveProductListUsingPOST({
          param: checkedData
        })

        alert('데이터가 갱신되었습니다.')
        await fetchProductData()
      } catch (e) {
        console.error(e)
      }
    }

    const getDateTimeLocalValue = (date) => {
      return `${format(new Date(date), datePattern.INPUT_DATE)}T${format(
          new Date(date),
          datePattern.INPUT_TIME_24
      )}`;
    };

    const handleChangeDate = (idx, field, value) => {
      try {
        dataList.value[idx][field] = new Date(value).toISOString()
      } catch (error) {
        console.error(error);
      }
    };


    onMounted(() => {
      fetchProductData()
    })


    const excelUpload = () => {
      const uploadFileInputEl = ref(null)

      const handleUploadButtonClick = () => {
        console.log('handleUploadButtonClick', uploadFileInputEl.value)
        if (uploadFileInputEl.value) {
          uploadFileInputEl.value.click()
        }
      }

      const handleChangeExcelInput = async (e) => {
        if (e.target.files && e.target.files[0]) {
          console.log('e.target.files[0]', e.target.files[0]);
          const { data: { data } } = await partnerAPI.multiProduct.handleFileUploadUsingPOST({
            file: e.target.files[0]
          })
          console.log(data)
          await fetchProductData()
        }
      }

      return {
        uploadFileInputEl,
        handleUploadButtonClick,
        handleChangeExcelInput
      }
    }

    return {
      dataList,
      categoryDepth1Options: categoryDepth1OptionsRaw,
      shippingTypeOptions,
      handleAllCheck,
      handleCheck,
      handleChangeCategoryDepth1,
      handleChangeSellOption,
      handleImageUpload,
      handleRemoveProductImage,
      handleAddTenRow,
      handleValidation,
      handleDelete,
      handleSaveData,
      handleClickPreview,
      getDateTimeLocalValue,
      handleChangeDate,
      ...excelUpload()
    }
  },
}
</script>

<style lang="scss">

.table-bottom-buttons {
  .table-bottom-line {
    width: 1px;
    height: 18px;
    background: #C4C4C4;;
  }
  .button-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
}
.search-top {
  .search-box {
    .Filter__textInput {
      width: calc(132px * 4 + 0.5rem * 3);
      background-image: url('../../../assets/ico_Search.svg');
      background-repeat: no-repeat;
      background-position: 14px center;
      border-radius: 4px;
      &::placeholder {
        color: #888;
      }
    }
    .search-button {
      width: 132px;
    }
  }
  select.FormSelect {
    background: #fff url('../../../assets/icon_select_small.svg') no-repeat 95% 50%;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  .ListFilter {
    display: flex;
    align-items: flex-end;
  }

  .FilterItem {
    width: 132px;
    display: inline-flex;
    flex-direction: column;

    & + & {
      margin-left: 6px;
    }

    & > label {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 8px;
    }

    & > input {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      background: #ffffff;
      border: 1px solid #dddddd;
      padding: 0 16px;
      height: 40px;
      background: url('../../../assets/ico_selectbar_arrow.png') no-repeat;
      background-size: 10px;
      background-position: calc(100% - 14px) center;

      &.isNotSelected {
        color: #999;
      }
    }

    & > input {
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    & > select {
      &.isNotSelected {
        color: #999;
      }
    }
  }
}
.AppLayout_routeView {
  padding: 64px 0 64px 36px;
}
.table-area {
  position: relative;
  z-index: 11;
  overflow: scroll;
  width: calc(100vw - 36px - 88px);
  overflow-y: hidden;
}
.table {
  white-space: nowrap;
  margin: 0;
  border: none;
  table-layout: fixed;
  .ProductImageItem {
    .product-img-button {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #4176FF;
      display: none;
      justify-content: center;
      align-items: center;
    }
    &:hover .product-img-button {
      display: flex;
    }
  }
  .required {
    color: #e70e0e;
  }
  thead th {
    padding: 10px 8px;
    position: sticky;
    top: 0;
    z-index: 1;
    color: #262626;
    background: #fff;
    &:nth-child(1) {
      position: sticky;
      z-index: 2;
      left: 0;
    }
    &:nth-child(2) {
      position: sticky;
      z-index: 2;
      left: 84px;
    }
    &:nth-child(3) {
      position: sticky;
      z-index: 2;
      left: 184px;
    }
    &:nth-child(4) {
      position: sticky;
      z-index: 2;
      left: 290px;
    }
  }
  .table-width {
    width: 84px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 106px;
    }
    &:nth-child(4) {
      width: 300px;
    }
    &:nth-child(5) {
      width: 140px;
    }
    &:nth-child(6) {
      width: 140px;
    }
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10){
      width: 206px;
    }
    &:nth-child(11) {
      width: 180px;
    }
    &:nth-child(12) {
      width: 140px;
    }
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15) {
      width: 100px;
    }
    &:nth-child(16) {
      width: 140px;
    }
    &:nth-child(17) {
      width: 240px;
    }
    &:nth-child(18) {
      width: 100px;
    }
    &:nth-child(19) {
      width: 240px;
    }
    &:nth-child(20) {
      width: 140px;
    }
    &:nth-child(21),
    &:nth-child(22),
    &:nth-child(23) {
      width: 206px;
    }
    &:nth-child(24),
    &:nth-child(25) {
      width: 400px;
    }
    &:nth-child(26),
    &:nth-child(27) {
      width: 206px;
    }
    &:nth-child(28),
    &:nth-child(29),
    &:nth-child(30) {
      width: 160px;
    }
    &:nth-child(31) {
      width: 140px;
    }
    &:nth-child(32) {
      width: 120px;
    }
    &:nth-child(33) {
      width: 90px;
    }
    &:nth-child(34),
    &:nth-child(35),
    &:nth-child(36),
    &:nth-child(37) {
      width: 160px;
    }
  }
  .table-td {
    background: #FFFFFF;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      background: #F1F1F1;
    }
    &:nth-child(1) {
      position: sticky;
      z-index: 2;
      left: 0;
    }
    &:nth-child(2) {
      position: sticky;
      z-index: 2;
      left: 84px;
    }
    &:nth-child(3) {
      position: sticky;
      z-index: 2;
      left: 184px;
    }
    &:nth-child(4) {
      position: sticky;
      z-index: 2;
      left: 290px;
    }
  }
  tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .table-width {
    &:first-child {
      justify-content: flex-start;
    }
  }
  tr {
    display: flex;
    align-items: stretch;
  }
  thead {
    background: white;
    tr {
      th {
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
  tbody {
    background: white;
    tr {
      text-align: center;
      box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
      .table-td {
        min-height: 54px;
        padding: 18px 8px;
        &.file-td {
          padding: 5px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .image-preview {
    width: 46px;
    background: #4176FF;
    display: inline-block;
    height: 46px;
  }
  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    color: #2563eb;
    background-color: #fff;
    border-color: #D9D9D9;
    border-width: 1px;
    --tw-shadow: 0 0 #0000;
    &:checked {
      background-image: url('../../../assets/input_check-primary.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  .input-file-images {
    width: calc(100% - 50px);
    display: flex;
    flex-wrap: wrap;
  }
  .input-file-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .file- {
      &label {
        padding-left: 46px;
        height: 30px;
        display: inline-block;
        background-image: url('../../../assets/icon_upload.svg');
        background-repeat: no-repeat;
        background-position: 0;
        line-height: 30px;
      }
      &input {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        margin: -1px;
        width: 1px;
        height: 1px;
      }
    }
  }
  .register-input {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1px 10px;
    line-height: 1;
    min-height: 22px;
    font-size: 12px;
    color: #262626;
    &:focus {
      outline: none;
      border-color: #1890FF;
      --tw-ring-offset-width: 0px;
      --tw-ring-opacity: 0.1;
      --tw-ring-color: rgba(73, 205, 218, var(--tw-ring-opacity));
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    }
    &.error {
      --tw-ring-offset-width: 0px;
      --tw-ring-opacity: 0.1;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      border-color: #FF6F6F;
      --tw-ring-color: rgba(255, 111, 111, var(--tw-ring-opacity));
      &:focus {
        border-color: #FF6F6F;
        --tw-ring-color: rgba(255, 111, 111, var(--tw-ring-opacity));
      }
    }
    &.full {
      width: 100%;
    }
    &.select {
      background-position: 95% 50%;
      background-image: url('../../../assets/icon_select_small.svg');
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 3px 10px;
      &::-ms-expand {
        display: none;
      }
    }
    &.textarea {
      min-height: 42px;
      &:focus {
        height: 300px;
      }
    }
  }
}

</style>
